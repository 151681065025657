<template>
  <v-container fluid>
    <Tabla
      :items="{ headers, garantias, titulo: 'Garantías' }"
      @actualizar="actualizar"
    ></Tabla>
  </v-container>
</template>

<script>
import PostService from "../../PostService";
import Tabla from "../../components/admin/Tabla";
export default {
  name: "admin-garantias",
  components: {
    Tabla
  },
  data: () => ({
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id_activar"
      },
      { text: "Nombre", value: "nombre" },
      { text: "Apellido Paterno", value: "apellido_paterno" },
      { text: "Apellido Materno", value: "apellido_materno" },
      { text: "Fecha Venta", value: "fecha_venta" },
      { text: "Modelo", value: "modelo" },
      { text: "Lugar Adquisición", value: "lugar_adquisicion" },
      { text: "Numero Cupon", value: "numero_cupon" },
      { text: "Numero Serie", value: "numero_serie" },
      { text: "Telefono", value: "telefono" },
      { text: "Acciones", value: "action", sortable: false }
    ],
    garantias: []
  }),
  created() {
    this.actualizar();
  },
  methods: {
    async actualizar() {
      const logged = this.$store.getters.token;
      if (logged === "") return this.$router.push({ name: "admin" });
      const datosGarantias = await PostService.getGarantias(logged);
      this.garantias = datosGarantias.data;
    }
  }
};
</script>

<style></style>
